import React from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { priceInfo, accountPriceInfo, upgradePriceInfo, getUrlReferralCode, setUrlReferralCode, getUrlExtraFamilies, getProductPricing } from "../../services/orderProcessing";
import { viewOrderPlacementDetails } from "../../services/customerInfo";
import { getToken, getUser } from "../../services/auth";
import styles from "./index.module.css";

import DropDown from "../../shared/dropDown";
import SelectAddOns from "../../shared/selectAddOns";
import PriceTotal from "../../shared/priceTotal";
import PaymentStructure from "../../shared/paymentStructure";
import PlanInformation from "../../shared/planInformation";
import { getAccountPrefix, getAdditionalServicesString, getCloudB2B2CProductIdFromTier, getCustomAUMOptionList, getCustomDropdownOptionIDFromValue, getDropdownCustomValue, getDropdownText, getIsCustomAUMDropdownShown } from "../../common/gFunctions";
import ProceedButton from "../proceedButton";
import { DesktopProducts, CloudProducts, SystemTypes, idToText, CloudB2CPaidProducts, CloudB2B2CPaidProducts, B2XDropdownOptionsWithSelectForBuyPage, CloudB2B2CProductsPricingPage, CloudB2B2CPaidProductsByTierForSelectProduct, CloudB2B2CProductsOldPricingPage, CloudB2CProductsPricingPage, CloudB2B2CProductsPricingPageAll, DesktopB2CPaidProducts, CustomAUMLimitDropdown_B2C, CustomAUMLimitDropdown_B2B2C, DesktopB2B2CPaidProductsByTierForSelectProduct } from "../../common/dropdowns";
import SelectAdditionalService from "../../shared/selectAdditionalService";
import { Additional_Services, B2B2C_Tier, B2X, CloudB2B2C_ProductIDsToNumPortfolios, CloudB2C_ProductIDs, CustomAUMLimit_Options, DesktopB2B2C_ProductIDsToNumPortfolios, DesktopB2C_ProductIDs, Order_Type, Product_Type, System_Type } from "../../common/constants";
import DefaultLoader from "../../shared/loader";

import Tooltip from "../../shared/Tooltip"
import SelectCountField from "../../shared/selectCountField";
import { decimal } from "../../common/numFormat";
import PricingCardModal from "../pricingCardModal";

const IsLegacyProduct_DefaultDesktop = true;
const IsLegacyProduct_DefaultCloud = false;

class SelectProductComponent extends React.Component {
    constructor(props) {
        super(props);
        const user = getUser();
        const token = user && user.access_token;
        const isTemp = user && user.id==='TEMP';


        const productId = (this.props.location && this.props.location.state && this.props.location.state.productId) || -999;
        const b2x = (this.props.location && this.props.location.state && this.props.location.state.b2x) || -999;
        const customAUMLimit = (this.props.location && this.props.location.state && this.props.location.state.customAUMLimit) || -999;
        const b2b2cTier = (this.props.location && this.props.location.state && this.props.location.state.b2b2cTier) || -999;
        var customAUMLimitOption = -999;
        const isFromPlanRecommendation = (this.props.location && this.props.location.state && this.props.location.state.isFromPlanRecommendation) || false;
        if (customAUMLimit > -999) {
            customAUMLimitOption = getCustomDropdownOptionIDFromValue(customAUMLimit, getCustomAUMOptionList(b2x)) || -999;
        }
        console.log("🚀 ~ SelectProductComponent ~ constructor ~ customAUMLimit:", customAUMLimit, customAUMLimitOption)

        this.state = {
            isLegacyProduct: IsLegacyProduct_DefaultCloud,
            systemType: System_Type.CLOUD,
            productId,
            B2X: b2x,
            numDesktopAddOns: 0,
            minNumDesktopAddOns: 0,
            numCloudAddOns: 0,
            numCloudUsers: 1,
            numDesktopUsers: 1,
            existingCloudUsersForMinValidation: 1,
            existingDesktopUsersForMinValidation: 1,
            existingFamiliesForMinValidation: 1,
            minNumCloudUsers: 1,
            minNumCloudAddOns: 0,
            numFamiliesTotal: 0,
            minNumFamiliesTotal: 0,
            b2b2cTier,
            customAUMLimitOption,
            isFromPlanRecommendation,
            isApplyReferralPoints: false,
            tempDiscountCode: "",
            discountCode: "",
            isEditingCouponCode: false,
            existingPlan: null,
            additionalServices: {
                [Additional_Services.XIRR_INVESTORREPORTS]: false,
                [Additional_Services.PMS_PACK]: false
            },
            isUpgrade: true,
            Discount: null,
            DiscountedAmount: null,
            TotalTax: null,
            FinalAmount: null,
            LicenseExpDt: null,
            priceDetails: null,
            proceedLoading: false,
            proceedDisabled: false,
            isTemp,
            token,
            isPricingCardModalOpen: false
        };

        if (this.props.selectedProduct) {
            var selectedProduct = this.props.selectedProduct;
            console.log("🚀 ~ SelectProductComponent ~ constructor ~ selectedProduct:", selectedProduct)
            this.state = {
                ...this.state,
                B2X: selectedProduct.b2x,
                systemType: selectedProduct.systemType,
                productId: selectedProduct.productId,
                customAUMLimitOption: selectedProduct.customAUMLimitOption,
                b2b2cTier: selectedProduct.b2b2cTier,
                numDesktopAddOns: selectedProduct.numDesktopAddOns,
                numCloudAddOns: selectedProduct.numCloudAddOns,
                numCloudUsers: selectedProduct.numCloudUsers,
                numDesktopUsers: selectedProduct.numDesktopUsers,
                numFamiliesAdditional: selectedProduct.numFamiliesAdditional,
                numFamiliesTotal: selectedProduct.numFamiliesTotal,
                discountCode: selectedProduct.discountCode,
                tempDiscountCode: selectedProduct.discountCode,
                additionalServices: selectedProduct.additionalServices,
                isApplyReferralPoints: selectedProduct.isApplyReferralPoints,
                isFromPlanRecommendation: selectedProduct.isFromPlanRecommendation
            }
        }
    }

    componentDidMount() {
        var code = getUrlReferralCode();

        var showExtraFamilies = getUrlExtraFamilies();

        if (showExtraFamilies) {
            this.setState({showExtraFamiliesFromURL: true, showExtraFamilies: true})
        }

        if (code && code !== '' && code !== 'undefined' && code !== 'null') {
            this.setState({tempDiscountCode: code, discountCode: code, codeFromUrl: true});
            setTimeout(() => {
                this.applyDiscountCode();
            })
        }


        if (this.state.token) {
            this.getCustomerDetails(this.state.token);
        } else {
            this.setState({isUpgrade: false});
            this.updatePriceInfo();
        }
    }

    onTogglePricingCardModal = (isOpen) => {
        this.setState({isPricingCardModalOpen: isOpen});
    }

    getCustomerDetails = (token) => {
        this.setState({isExistingPlanLoading: true})
        viewOrderPlacementDetails(token)
            .then(({error,response}) => {
                if (error >= 400) {
                    console.log(error);
                } else {
                    this.props.setOrderPlacementDetails(response);
                    var isRenewalEligibleLegacyProduct = response.Plan.IsLegacyToNewRenewalRequired && response.Plan.RenewalEligible;

                    if (response.Plan.IsLegacyToNewRenewalRequired && !response.Plan.RenewalEligible) {
                        navigate('/account/plan');
                    }
                    else if (isRenewalEligibleLegacyProduct && (!(this.state.productId > 0) || !(this.state.B2X > 0))) {
                        console.log("🚀 ~ SelectProductComponent ~ .then ~ isRenewalEligibleLegacyProduct && (!(this.state.productId > 0) || !(this.state.B2X > 0)):", isRenewalEligibleLegacyProduct, this.state.productId, this.state.B2X)
                        navigate('/account/new-plan', {state: {isDesktop: response.Plan.SystemType === System_Type.DESKTOP}})
                    }
                    else {
                        this.setState({
                            isRenewalEligibleLegacyProduct,
                            existingPlan: response.Plan,
                            isExistingPlanLoading: false,
                            isApplyReferralPoints: response.Plan && response.Plan.ReferralPoints > 0,
                            isLegacyProduct: response.Plan && (response.Plan.IsLegacyProduct && !response.Plan.IsFree) && !isRenewalEligibleLegacyProduct ? response.Plan.IsLegacyProduct : IsLegacyProduct_DefaultCloud,
                            customerDetails: response.CustomerDetails
                        });
                        this.adjustFields(response.Plan);
                        this.updatePriceInfo();
                    }
                }
            })
    }

    adjustFields = (planDetails) => {
            // if (!planDetails.RenewalEligible) {
            var numDesktopAddOns = planDetails.NumDesktopAddOns;
            var numCloudAddOns = planDetails.NumCloudAddOns;
            var numCloudUsers = planDetails.NumCloudUsers;
            var numCloudUsersDefault = planDetails.NumCloudUsersDefault;
            var numDesktopUsers = planDetails.NumDesktopUsers;
            var numDesktopUsersDefault = planDetails.NumDesktopUsersDefault;
            var numFamiliesTotal = planDetails.NumFamiliesTotal;
            var numFamiliesDefault = planDetails.NumFamiliesDefault;
            const isFree = planDetails.IsFree;
            const isCloud = planDetails.SystemType === System_Type.CLOUD;
            var productId = Math.max(planDetails.ProductId,this.state.productId);
            var b2b2cTier = Math.max(planDetails.B2B2C_Tier,this.state.b2b2cTier);
            var additionalServices = {};
            for (var key in this.state.additionalServices) {
                additionalServices[key] = planDetails.AdditionalServices.includes(+key) || this.state.additionalServices[key];
            }

            if (productId === 100) {
                productId = -999;
            } else if (productId === 300) {
                productId = -999;
            } else if (productId === CloudB2C_ProductIDs.FREE && planDetails.B2X == B2X.B2C) {
                productId = (planDetails.Trial && (planDetails.Trial.ProductId > CloudB2C_ProductIDs.FREE && planDetails.Trial.ProductId !== CloudB2C_ProductIDs.PLUS) ? planDetails.Trial.ProductId : -999);
                if (productId > CloudB2C_ProductIDs.FREE) {
                    numCloudUsers = planDetails.Trial.NumCloudUsers;
                    // numCloudUsersDefault = planDetails.Trial.NumCloudUsersDefault;
                    numCloudAddOns = planDetails.Trial.NumCloudUsers - planDetails.Trial.NumCloudUsersDefault;
                    numFamiliesTotal = planDetails.Trial.NumFamiliesTotal;
                    // numFamiliesDefault = planDetails.Trial.NumFamiliesDefault;
                }
            } else if (productId === CloudB2C_ProductIDs.FREE && planDetails.B2X == B2X.B2B2C) {
                productId = (planDetails.Trial && (planDetails.Trial.ProductId > CloudB2C_ProductIDs.FREE) ? planDetails.Trial.ProductId : -999);
                b2b2cTier = (planDetails.Trial && (planDetails.Trial.ProductId > CloudB2C_ProductIDs.FREE) ? planDetails.Trial.B2B2C_Tier : planDetails.B2B2C_Tier);
                this.selectB2X(B2X.B2B2C,productId,b2b2cTier);
                return;
            }
            var customAUMLimitOption = this.state.customAUMLimitOption === -999 ? getCustomDropdownOptionIDFromValue(planDetails.CustomAUMLimit, getCustomAUMOptionList(planDetails.B2X)) : this.state.customAUMLimitOption;
            this.setState({
                productId: productId,
                customAUMLimitOption,
                existingCustomAUMLimitOption: getCustomDropdownOptionIDFromValue(planDetails.CustomAUMLimit, getCustomAUMOptionList(planDetails.B2X)),
                b2b2cTier: b2b2cTier,
                isUpgrade: !isFree && !planDetails.RenewalEligible,
                minNumDesktopAddOns: planDetails.RenewalEligible || isFree ? 0 : numDesktopAddOns,
                minNumCloudAddOns: planDetails.RenewalEligible || isFree ? 0 : numCloudAddOns,
                numDesktopAddOns: Math.max(numDesktopAddOns,this.state.numDesktopAddOns),
                numCloudAddOns: Math.max(numCloudAddOns,this.state.numCloudAddOns),
                numCloudUsers: Math.max(numCloudUsers, isCloud ? this.state.numCloudUsers : 0),
                numDesktopUsers: Math.max(numDesktopUsers, this.state.numDesktopUsers),
                existingDesktopUsersForMinValidation: planDetails.RenewalEligible || isFree ? isCloud ? 1 : 0 : planDetails.NumDesktopUsers,
                existingCloudUsersForMinValidation: planDetails.RenewalEligible || isFree ? isCloud ? 1 : 0 : planDetails.NumCloudUsers,
                existingFamiliesForMinValidation: planDetails.RenewalEligible || isFree ? 1 : planDetails.NumFamiliesTotal,
                minNumCloudUsers: planDetails.RenewalEligible || isFree ? numCloudUsersDefault : Math.max(planDetails.NumCloudUsers, numCloudUsersDefault),
                minNumDesktopUsers: planDetails.RenewalEligible || isFree ? numDesktopUsersDefault : Math.max(planDetails.NumDesktopUsers, numDesktopUsersDefault),
                numFamiliesTotal: Math.max(numFamiliesTotal,this.state.numFamiliesTotal),
                minNumFamiliesTotal: planDetails.RenewalEligible || isFree ? numFamiliesDefault : Math.max(planDetails.NumFamiliesTotal, numFamiliesDefault),
                showExtraFamilies: this.state.showExtraFamiliesFromURL || (Math.max(numFamiliesTotal,this.state.numFamiliesTotal) > numFamiliesDefault),
                additionalServices,
                existingAdditionalServices: additionalServices,
                systemType: planDetails.SystemType
            });
            // }
    }

    selectProductId = (item) => {
        this.setState({
            productId: item.id
        });
    
        setTimeout(() => {
            this.updatePriceInfo(true);
        })
    }

    selectB2B2CTier = (item) => {
        var numPortsAllowed = (this.state.priceDetails && this.state.priceDetails.NewPlan && this.state.priceDetails.NewPlan.NumPortsAllowed) || CloudB2B2C_ProductIDsToNumPortfolios[this.state.productId] || -1;
        this.setState({
            b2b2cTier: item.id,
            priceDetails: null,
            productId: numPortsAllowed > 0 ? getCloudB2B2CProductIdFromTier(item.id, numPortsAllowed) : -999
        });
    
        setTimeout(() => {
            this.updatePriceInfo(true);
        })
    }

    selectSystemType = (item) => {
        this.setState({
            systemType: item.id,
            productId: -999,
            priceDetails: null,
            numDesktopAddOns: 0,
            minNumDesktopAddOns: 0,
            numCloudAddOns: 0,
            minNumCloudAddOns: 0,
            numCloudUsers: 1,
            minNumCloudUsers: 1,
            numDesktopUsers: 1,
            minNumDesktopUsers: 1,
            isLegacyProduct: this.state.existingPlan ? this.state.isLegacyProduct : (item.id == System_Type.CLOUD ? IsLegacyProduct_DefaultCloud : IsLegacyProduct_DefaultDesktop)
        });
    }

    selectB2X = (b2x, productId = -999, b2b2cTier = -1) => {
        this.setState({
            B2X: b2x,
            isUpgrade: false,
            productId: productId,
            priceDetails: null,
            numDesktopAddOns: 0,
            minNumDesktopAddOns: 0,
            numCloudAddOns: 0,
            minNumCloudAddOns: 0,
            numCloudUsers: 1,
            minNumCloudUsers: 1,
            numDesktopUsers: 1,
            minNumDesktopUsers: 1
        });

        if (b2b2cTier > 0) {
            this.setState({
                b2b2cTier
            })
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
        [name]: value
        });

        // setTimeout(() => {
        //     this.updatePriceInfo();
        // })
    }

    getCustomAUMLimitValueForAPI = () => {
        if (getIsCustomAUMDropdownShown(this.state.productId, this.state.b2b2cTier)) {
            return getDropdownCustomValue(this.state.customAUMLimitOption, getCustomAUMOptionList(this.state.B2X));
        } else {
            return null;
        }
    }

    getIsShouldProceedBeDisabledDueToCustomAUM = (showCustomAUMDropdown) => {
        return showCustomAUMDropdown && this.state.customAUMLimitOption <= CustomAUMLimit_Options.CONTACT_MPROFIT;
    }

    updatePriceInfo = (isChangeProduct = false) => {
        var showCustomAUMDropdown = getIsCustomAUMDropdownShown(this.state.productId, this.state.b2b2cTier);
        var shouldProceedBeDisabledDueToCustomAUM = this.getIsShouldProceedBeDisabledDueToCustomAUM(showCustomAUMDropdown);
        console.log("🚀 ~ SelectProductComponent ~ shouldProceedBeDisabledDueToCustomAUM:", shouldProceedBeDisabledDueToCustomAUM, this.state.customAUMLimitOption)
        if (this.state.productId !== -999 && this.state.productId != null && this.state.systemType >= 0 && !shouldProceedBeDisabledDueToCustomAUM) {
            this.setState({isEditingCouponCode: false});

            const additionalServicesString = getAdditionalServicesString(this.state.additionalServices);

            var numCloudUsers = this.state.systemType == System_Type.CLOUD ? (+this.state.numCloudAddOns)+1 : this.state.numCloudAddOns;
            if (!this.state.isLegacyProduct) {
                numCloudUsers = this.state.numCloudUsers;
            }

            var numDesktopUsers = this.state.systemType == System_Type.DESKTOP ? (+this.state.numDesktopAddOns)+1 : 0;
            if (!this.state.isLegacyProduct && this.state.systemType === System_Type.DESKTOP) {
                numDesktopUsers = this.state.numDesktopUsers;
            }

            var wasNumCloudUsersGreatherThanMin = this.state.numCloudUsers > this.state.minNumCloudUsers;
            var wasNumDesktopUsersGreatherThanMin = this.state.numDesktopUsers > this.state.minNumDesktopUsers;
            var wasNumFamiliesTotalGreatherThanMin = this.state.numFamiliesTotal > this.state.minNumFamiliesTotal;

            var customAUMLimit = this.getCustomAUMLimitValueForAPI();

            if (!this.state.existingPlan || this.state.isTemp) {
                priceInfo(this.state.productId,this.state.systemType,numDesktopUsers,numCloudUsers,this.state.numFamiliesTotal,additionalServicesString,this.state.discountCode,customAUMLimit)
                    .then(({error,response}) => {
                        if (error.status>=400) {
                            console.log(error);
                        } else {
                            this.setState({
                                priceDetails: response,
                                numCloudUsers: response ? response.NewPlan.NumCloudUsers : this.state.numCloudUsers,
                                numCloudUsersDefault: response ? response.NewPlan.NumCloudUsersDefault : this.state.numCloudUsersDefault,
                                minNumCloudUsers: response ? Math.max(response.NewPlan.NumCloudUsersDefault, this.state.existingCloudUsersForMinValidation) : this.state.minNumCloudUsers,
                                numDesktopUsers: response ? response.NewPlan.NumDesktopUsers : this.state.numDesktopUsers,
                                numDesktopUsersDefault: response ? response.NewPlan.NumDesktopUsersDefault : this.state.numDesktopUsersDefault,
                                minNumDesktopUsers: response ? Math.max(response.NewPlan.NumDesktopUsersDefault, this.state.existingDesktopUsersForMinValidation) : this.state.minNumDesktopUsers,
                                numFamiliesTotal: response ? response.NewPlan.NumFamiliesTotal : this.state.numFamiliesTotal,
                                numFamiliesDefault: response ? response.NewPlan.NumFamiliesDefault : this.state.numFamiliesDefault,
                                minNumFamiliesTotal: response ? Math.max(response.NewPlan.NumFamiliesDefault, this.state.existingFamiliesForMinValidation) : this.state.minNumFamiliesTotal
                            })
                        }
                    });
            } else if (!this.state.isUpgrade) {
                accountPriceInfo(this.state.productId,this.state.systemType,numDesktopUsers,numCloudUsers,this.state.numFamiliesTotal,-1,additionalServicesString,this.state.discountCode,Order_Type.RENEWAL,getToken(),this.state.isApplyReferralPoints,customAUMLimit)
                    .then(({error,response}) => {
                        if (error.status>=400) {
                            console.log(error);
                        } else {
                            this.setState({
                                priceDetails: response,
                                numCloudUsers: response ? response.NewPlan.NumCloudUsers : this.state.numCloudUsers,
                                numCloudUsersDefault: response ? response.NewPlan.NumCloudUsersDefault : this.state.numCloudUsersDefault,
                                minNumCloudUsers: response ? Math.max(response.NewPlan.NumCloudUsersDefault, this.state.existingCloudUsersForMinValidation) : this.state.minNumCloudUsers,
                                numDesktopUsers: response ? response.NewPlan.NumDesktopUsers : this.state.numDesktopUsers,
                                numDesktopUsersDefault: response ? response.NewPlan.NumDesktopUsersDefault : this.state.numDesktopUsersDefault,
                                minNumDesktopUsers: response ? Math.max(response.NewPlan.NumDesktopUsersDefault, this.state.existingDesktopUsersForMinValidation) : this.state.minNumDesktopUsers,
                                numFamiliesTotal: response ? response.NewPlan.NumFamiliesTotal : this.state.numFamiliesTotal,
                                numFamiliesDefault: response ? response.NewPlan.NumFamiliesDefault : this.state.numFamiliesDefault,
                                minNumFamiliesTotal: response ? Math.max(response.NewPlan.NumFamiliesDefault, this.state.existingFamiliesForMinValidation) : this.state.minNumFamiliesTotal,
                                showExtraFamilies: this.state.showExtraFamiliesFromURL || (Math.max(response.NewPlan.NumFamiliesTotal,this.state.numFamiliesTotal) > response.NewPlan.NumFamiliesDefault),
                            })
                        }
                    });
            } else if (additionalServicesString != "") {
                if (!this.shouldProceedBeDisabled()) {
                    accountPriceInfo(this.state.productId,this.state.systemType,numDesktopUsers,numCloudUsers,this.state.numFamiliesTotal,-1,additionalServicesString,null,Order_Type.REGULAR_UPGRADE,getToken(),this.state.isApplyReferralPoints,customAUMLimit)
                        .then(({error,response}) => {
                            if (error.status>=400) {
                                console.log(error);
                            } else {
                                this.setState({
                                    additionalServicesPricing: response.NewPlan.SystemType == System_Type.DESKTOP ? response.DesktopProduct.AdditionalServicesPricing : response.CloudProduct.AdditionalServicesPricing,
                                })
                            }
                        })
                } else {
                    this.setState({additionalServicesPricing: null});
                }

            } else if (this.state.isUpgrade) {
                getProductPricing(this.state.productId).then(({error,response}) => {
                    if (error.status >= 400) {
                        console.log(error);
                    } else {
                        console.log(response, 'getProductPricingResponse');

                        var minNumCloudUsers = !this.state.isLegacyProduct ? Math.max(response.NumCloudUsersDefault, this.state.existingCloudUsersForMinValidation) : this.state.minNumCloudUsers;
                        var minNumDesktopUsers = !this.state.isLegacyProduct ? Math.max(response.NumDesktopUsersDefault, this.state.existingDesktopUsersForMinValidation) : this.state.minNumDesktopUsers;
                        var minNumFamiliesTotal = !this.state.isLegacyProduct ? Math.max(response.NumFamiliesDefault, this.state.existingFamiliesForMinValidation) : this.state.minNumFamiliesTotal;

                        this.setState({
                            productPricing: response,
                            minNumCloudUsers,
                            minNumDesktopUsers,
                            numCloudUsers: !this.state.isLegacyProduct && isChangeProduct && (!wasNumCloudUsersGreatherThanMin || minNumCloudUsers > this.state.numCloudUsers) ? minNumCloudUsers : this.state.numCloudUsers,
                            numDesktopUsers: !this.state.isLegacyProduct && isChangeProduct && (!wasNumDesktopUsersGreatherThanMin || minNumDesktopUsers > this.state.numDesktopUsers) ? minNumDesktopUsers : this.state.numDesktopUsers,
                            numFamiliesTotal: !this.state.isLegacyProduct && isChangeProduct && (!wasNumFamiliesTotalGreatherThanMin || minNumFamiliesTotal > this.state.numFamiliesTotal) ? minNumFamiliesTotal : this.state.numFamiliesTotal
                            // numCloudUsers: !this.state.isLegacyProduct && minNumCloudUsers > this.state.numCloudUsers ? minNumCloudUsers : this.state.numCloudUsers,
                            // numFamiliesTotal: !this.state.isLegacyProduct && minNumFamiliesTotal > this.state.numFamiliesTotal ? minNumFamiliesTotal : this.state.numFamiliesTotal
                        })
                    }
                })
            }
        } else {
            this.setState({priceDetails: null, productPricing: null});
        }
    }

    adjustAddOns = (type, increase, min) => {
        var increment = this.state[type] + 1 * (increase ? 1 : -1);
        if (increment >= min) {
            this.setState({[type] : increment});

            setTimeout(() => {
                this.updatePriceInfo();
            })
        }
    }

    adjustCount = (fieldName, increase, min) => {
        var increment = this.state[fieldName] + 1 * (increase ? 1 : -1);
        if (increment >= min) {
            this.setState({[fieldName] : increment});

            setTimeout(() => {
                this.updatePriceInfo();
            })
        }
    }

    applyDiscountCode = () => {
        this.setState({discountCode: this.state.tempDiscountCode });
        setTimeout(() => {
            this.updatePriceInfo();
        });
    }

    cancelDiscountCode = () => {
        this.setState({discountCode: "", tempDiscountCode: ""});
        setTimeout(() => {
            this.updatePriceInfo();
        });
    }

    proceed = (isDisabled) => {
        if (!isDisabled) {
            var customAUMLimit = this.getCustomAUMLimitValueForAPI();

            const additionalServicesString = getAdditionalServicesString(this.state.additionalServices);

            this.setState({proceedLoading: true, proceedDisabled: true});
            this.props.setSelectedProduct({
                ...this.props.selectedProduct,
                b2x: this.state.B2X,
                b2b2cTier: this.state.b2b2cTier,
                systemType: this.state.systemType,
                productId: this.state.productId,
                numDesktopAddOns: this.state.numDesktopAddOns,
                numCloudAddOns: this.state.numCloudAddOns,
                numDesktopUsers: this.state.numDesktopUsers,
                numCloudUsers: this.state.numCloudUsers,
                numFamiliesTotal: this.state.numFamiliesTotal,
                discountCode: this.state.discountCode,
                additionalServices: this.state.additionalServices,
                isApplyReferralPoints: this.state.isApplyReferralPoints,
                customAUMLimitOption: this.state.customAUMLimitOption,
                isFromPlanRecommendation: this.state.isFromPlanRecommendation
            })
            if (this.state.priceDetails && !this.state.isUpgrade) {
                this.props.setPriceDetails(this.state.priceDetails);
                navigate(getAccountPrefix(this.state.token)+"/order/details");
            } else {
                var numCloudUsers = this.state.systemType == System_Type.CLOUD ? (+this.state.numCloudAddOns)+1 : this.state.numCloudAddOns;
                if (!this.state.isLegacyProduct) {
                    numCloudUsers = this.state.numCloudUsers;
                }
                var numDesktopUsers = this.state.systemType == System_Type.DESKTOP ? (+this.state.numDesktopAddOns)+1 : 0;
                if (!this.state.isLegacyProduct && this.state.systemType === System_Type.DESKTOP) {
                    numDesktopUsers = this.state.numDesktopUsers;
                }
                upgradePriceInfo(this.state.productId, this.state.systemType, numDesktopUsers, numCloudUsers, this.state.numFamiliesTotal, additionalServicesString, this.state.discountCode, getToken(), this.state.isApplyReferralPoints, customAUMLimit)
                    .then(({error,response}) => {
                        if (error.status >= 400) {
                            console.log('error',error);
                        } else {
                            this.props.setUpgradePriceInfo(response);
                            navigate("/account/order/upgrade");
                        }
                    })
            }
        }
    }

    filterProducts = (systemType, existingProductId, realExistingProductId, isLegacyProduct, b2x, isOnlyExactProduct) => {
        console.log("🚀 ~ SelectProductComponent ~ systemType1:", systemType, existingProductId, realExistingProductId, isLegacyProduct, b2x, isOnlyExactProduct)
        if (isLegacyProduct) {
            if (systemType === System_Type.DESKTOP) {
                return DesktopProducts.filter((p) => {
                            return (p.id === realExistingProductId);
                    });
            } else {
                return CloudProducts.filter((p) => {
                        return (p.id === realExistingProductId);
                    });
                }
        } else {
            if (systemType === System_Type.DESKTOP) {
                if (b2x == B2X.B2C) {
                    return DesktopB2CPaidProducts.filter((p) => {
                        return isOnlyExactProduct ? (p.id === realExistingProductId) : (p.id >= realExistingProductId || p.id === -999);
                    });
                } else {
                    return [];
                }
            } else {
                if (b2x == B2X.B2C) {
                    return CloudB2CPaidProducts.filter((p) => {
                        return isOnlyExactProduct ? (p.id === realExistingProductId) : (p.id >= realExistingProductId || p.id === -999);
                    });
                } else if (b2x == B2X.B2B2C) {
                    return CloudB2B2CPaidProducts.filter((p) => {
                        return isOnlyExactProduct ? (p.id === realExistingProductId) : (p.id >= realExistingProductId || p.id === -999);
                    });
                } else {
                    return [];
                }
            }
        }
    }

    filterCustomAUMList = (list, currentID, isUpgrade, isOnlyExactProduct) => {
        console.log("🚀 ~ SelectProductComponent ~ list, currentID, isUpgrade, isOnlyExactProduct:", list, currentID, isUpgrade, isOnlyExactProduct)
        if (!isUpgrade && !isOnlyExactProduct) return list;

        return list.filter((p) => {
            return isOnlyExactProduct ? (p.id === currentID) : (p.id >= currentID || p.id === -999);
        });
    }

    filterB2B2CProducts = (products, existingNumPortfolios, isUpgrade) => {
        if (!isUpgrade) return products;
        
        return products.filter((p) => {
            return (p.numPortfolios >= existingNumPortfolios || p.id === -999);
        });
    }

    filterTiers = (existingB2B2CTier) => {
        return (existingB2B2CTier >= B2B2C_Tier.ESSENTIAL && existingB2B2CTier <= B2B2C_Tier.PREMIUM ? CloudB2B2CProductsOldPricingPage : CloudB2B2CProductsPricingPage).filter((p) => {
            return (p.id >= existingB2B2CTier || p.id === -999);
        });
    }

    toggleAdditionalService = (type,e) => {
        this.setState({
            additionalServices: {
                ...this.state.additionalServices,
                [type]: e.target.checked
            }
        });
        
        setTimeout(() => {
            this.updatePriceInfo();
        });
    }

    getAdditionalServicesPrice = (type) => {

        var additionalServicesPricing;

        if (this.state.isUpgrade) {
            additionalServicesPricing = this.state.additionalServicesPricing;
        } else {
            if (this.state.priceDetails) {
                if (this.state.priceDetails.NewPlan.SystemType == System_Type.DESKTOP) {
                    additionalServicesPricing = this.state.priceDetails.DesktopProduct ? this.state.priceDetails.DesktopProduct.AdditionalServicesPricing : null;
                } else {
                    additionalServicesPricing = this.state.priceDetails.CloudProduct ? this.state.priceDetails.CloudProduct.AdditionalServicesPricing : null;
                }
            }
        }
        
        if (additionalServicesPricing) {
            return additionalServicesPricing.find(x => x.ServiceId == type)            
        } else {
            return {};
        }
    }

    isAdditionalServiceDisabled = (type) => {
        if (this.state.existingPlan && this.state.existingPlan.AdditionalServices) {
            return this.state.existingPlan.AdditionalServices.includes(type);
        } else {
            return false;
        }
    }

    shouldProceedBeDisabled = () => {
        var showCustomAUMDropdown = getIsCustomAUMDropdownShown(this.state.productId, this.state.b2b2cTier);
        var shouldProceedBeDisabledDueToCustomAUM = this.getIsShouldProceedBeDisabledDueToCustomAUM(showCustomAUMDropdown);
        var isDesktop = this.state.systemType == System_Type.DESKTOP;

        var proceedDisabled = this.state.proceedDisabled;
        var existingPlan = this.state.existingPlan;
        if (existingPlan) {
            if (this.state.IsLegacyProduct) {
                const exDesktopAddOns = existingPlan.NumDesktopUsers - (existingPlan.SystemType === System_Type.DESKTOP ? 1 : 0);
                const exCloudAddOns = existingPlan.NumCloudUsers - (existingPlan.SystemType === System_Type.CLOUD ? 1 : 0);

                var numExistingAddServices = existingPlan.AdditionalServices.length;
                var numNewAddServices = 0;
                for (var key in this.state.additionalServices) {
                    if (this.state.additionalServices[key]) {
                        numNewAddServices += 1;
                    }
                }

                proceedDisabled = proceedDisabled || (this.state.isUpgrade && (this.state.productId <= existingPlan.ProductId && this.state.numDesktopAddOns <= exDesktopAddOns && this.state.numCloudAddOns <= exCloudAddOns && (numNewAddServices <= numExistingAddServices))) || this.state.productId === -999 || shouldProceedBeDisabledDueToCustomAUM;
            } else {
                proceedDisabled = proceedDisabled || (this.state.isUpgrade && (this.state.productId <= existingPlan.ProductId && this.state.numCloudUsers <= existingPlan.NumCloudUsers && (isDesktop ? this.state.numDesktopUsers <= existingPlan.NumDesktopUsers : true) && this.state.customAUMLimitOption <= this.state.existingCustomAUMLimitOption && (existingPlan.NumFamiliesTotal < 0 || this.state.numFamiliesTotal <= existingPlan.NumFamiliesTotal))) || this.state.productId === -999 || shouldProceedBeDisabledDueToCustomAUM;
            }
        } else {
            proceedDisabled = this.state.productId === -999 || this.state.productId == null || shouldProceedBeDisabledDueToCustomAUM;
        }

        return proceedDisabled;
    }

    contactMProfitForUpgrade = () => {
        try {
            if (this.state.customerDetails) {
                window.FreshworksWidget('identify', 'ticketForm', {
                    name: this.state.customerDetails.FirstName + (this.state.customerDetails.LastName ? ' ' + this.state.customerDetails.LastName : ''),
                    email: this.state.customerDetails.EmailAddr,
                });
            }
            window.FreshworksWidget('prefill', 'ticketForm', {
                custom_fields: {
                  cf_customer_selected_type: 'Upgrade inquiry', //Number field
                }
            });
            window.FreshworksWidget('open', 'ticketForm');
        } catch {
            window.open('https://www.mprofit.in/contact-us/', "_blank");
        }
    }

    getCanBuyOnline = (existingPlan, isOldExpired) => {
        console.log("🚀 ~ SelectProductComponent ~ existingPlan:1", existingPlan, this.state.customAUMLimitOption, this.state.productId, this.state.b2b2cTier);
        if (this.state.showExtraFamilies) {

        } else if (isOldExpired) {
            return false;
        } else if (existingPlan && existingPlan.AdditionalServices && (existingPlan.AdditionalServices.includes(Additional_Services.API_PACK) || existingPlan.AdditionalServices.includes(Additional_Services.HOLDING_API))) {
            return false;
        } else if ((!existingPlan || ![CloudB2C_ProductIDs.FAMILYOFFICE, DesktopB2C_ProductIDs.DESKTOP_MULTIFAMILY].includes(existingPlan.ProductId)) && ![CloudB2C_ProductIDs.FAMILYOFFICE, DesktopB2C_ProductIDs.DESKTOP_MULTIFAMILY].includes(this.state.productId) && ![B2B2C_Tier.PLATINUM].includes(this.state.b2b2cTier)) {
            return true;
        } else if ([CloudB2C_ProductIDs.FAMILYOFFICE, DesktopB2C_ProductIDs.DESKTOP_MULTIFAMILY].includes(this.state.productId) || [B2B2C_Tier.PLATINUM].includes(this.state.b2b2cTier)) {
            if ((this.state.customAUMLimitOption === -999 || this.state.customAUMLimitOption > 0) && this.state.customAUMLimitOption <= CustomAUMLimit_Options.CR_100) {
                return true;
            } else {
                return false;
            }
        }
    }

    selectCustomAUMLimit = (item) => {
        this.setState({
            customAUMLimitOption: item.id
        });
    
        setTimeout(() => {
            this.updatePriceInfo(true);
        })
    }

    render() {
        var priceDetails = this.state.priceDetails || {};
        var {TotalTax, FinalAmount, Amount, Discount, DiscountedAmount} = priceDetails;
        var cloudSingleAddOnPrice, DiscountFields, cloudAdditionalFamilyPrice, isAdditionalFamiliesAllowed, isAdditionalUsersAllowed, numCloudUsersDefault, numFamiliesDefault, numDesktopUsersDefault;
        var isOldExpired = false;

        console.log("🚀 ~ file: index.js:583 ~ SelectProductComponent ~ render ~ this.state.priceDetails:", this.state.priceDetails)
        if (this.state.priceDetails) {
            if (this.state.priceDetails.NewPlan.SystemType == System_Type.CLOUD) {
                var {BasePrice, SingleAddOnPrice, AdditionalFamilyPrice, IsAdditionalFamiliesAllowed, IsAdditionalUsersAllowed} = this.state.priceDetails.CloudProduct;
                cloudSingleAddOnPrice = SingleAddOnPrice;
                cloudAdditionalFamilyPrice = AdditionalFamilyPrice;
                isAdditionalFamiliesAllowed = IsAdditionalFamiliesAllowed;
                isAdditionalUsersAllowed = IsAdditionalUsersAllowed;
                numCloudUsersDefault = this.state.priceDetails.NewPlan.NumCloudUsersDefault;
                numFamiliesDefault = this.state.priceDetails.NewPlan.NumFamiliesDefault;
            } else {
                var {BasePrice, SingleAddOnPrice, IsAdditionalFamiliesAllowed, IsAdditionalUsersAllowed} = this.state.priceDetails.DesktopProduct;
                if(this.state.priceDetails.CloudProduct){
                    cloudSingleAddOnPrice = this.state.priceDetails.CloudProduct.SingleAddOnPrice
                }
                isAdditionalFamiliesAllowed = false;
                isAdditionalUsersAllowed = IsAdditionalUsersAllowed;
                numCloudUsersDefault = this.state.priceDetails.NewPlan.NumCloudUsersDefault;
                numDesktopUsersDefault = this.state.priceDetails.NewPlan.NumDesktopUsersDefault;
                numFamiliesDefault = this.state.priceDetails.NewPlan.NumFamiliesDefault;
            }

            var LicenseExpDt = this.state.priceDetails.NewPlan.LicenseExpDt;
            DiscountFields = this.state.priceDetails.DiscountFields;
        } else if (this.state.productPricing) {
            var {IsAdditionalFamiliesAllowed, IsAdditionalUsersAllowed, NumCloudUsersDefault, NumDesktopUsersDefault, NumFamiliesDefault, SingleAddOnPrice, AdditionalFamilyPrice} = this.state.productPricing;
            cloudSingleAddOnPrice = SingleAddOnPrice;
            cloudAdditionalFamilyPrice = AdditionalFamilyPrice;
            isAdditionalFamiliesAllowed = IsAdditionalFamiliesAllowed;
            isAdditionalUsersAllowed = IsAdditionalUsersAllowed;
            numCloudUsersDefault = NumCloudUsersDefault;
            numDesktopUsersDefault = NumDesktopUsersDefault;
            numFamiliesDefault = NumFamiliesDefault;
        }

        var isLegacyProduct = this.state.isLegacyProduct;
        var showCustomAUMDropdown = getIsCustomAUMDropdownShown(this.state.productId, this.state.b2b2cTier);
        var shouldProceedBeDisabledDueToCustomAUM = this.getIsShouldProceedBeDisabledDueToCustomAUM(showCustomAUMDropdown);
        var isCloud = this.state.systemType == System_Type.CLOUD;

        const existingPlan = this.state.existingPlan;
        if (existingPlan && existingPlan.IsExpired && existingPlan.LicenseExpDt) {
            isOldExpired = new Date(existingPlan.LicenseExpDt) < new Date(2023, 0, 1);
        }
        const b2x = this.state.existingPlan && !this.state.isRenewalEligibleLegacyProduct ? this.state.existingPlan.B2X : this.state.B2X;
        const filteredProducts = this.state.existingPlan && this.state.existingPlan.ProductId == 300 ? this.filterProducts(this.state.systemType,0,0,false,B2X.B2C) : this.filterProducts(this.state.systemType,this.state.existingPlan && this.state.isUpgrade ? this.state.existingPlan.ProductId : 0, this.state.isRenewalEligibleLegacyProduct ? this.state.productId : (this.state.existingPlan ? this.state.existingPlan.ProductId : 0), this.state.existingPlan && !this.state.isRenewalEligibleLegacyProduct ? this.state.existingPlan.IsLegacyProduct : isLegacyProduct, b2x, this.state.isRenewalEligibleLegacyProduct);
        console.log("🚀 ~ SelectProductComponent ~ render ~ filteredProducts:", b2x, filteredProducts, this.state.isRenewalEligibleLegacyProduct)
        const filteredTiers = b2x == B2X.B2B2C ? CloudB2B2CProductsPricingPageAll : [];
        const numPortfoliosDropdownItems = b2x == B2X.B2B2C && this.state.b2b2cTier >= 0 ?
            (
                isCloud
                ?        
                this.filterB2B2CProducts(CloudB2B2CPaidProductsByTierForSelectProduct[this.state.b2b2cTier],CloudB2B2C_ProductIDsToNumPortfolios[this.state.existingPlan && this.state.existingPlan.ProductId],this.state.isUpgrade)
                :
                this.filterB2B2CProducts(DesktopB2B2CPaidProductsByTierForSelectProduct[this.state.b2b2cTier],DesktopB2B2C_ProductIDsToNumPortfolios[this.state.existingPlan && this.state.existingPlan.ProductId],this.state.isUpgrade)
            )
            :
            [];

        const activeProduct = filteredProducts.find((product)=>this.state.productId === product.id);
        const title = activeProduct ? activeProduct.text : '';

        const activeTier = filteredTiers.find((tier) => this.state.b2b2cTier == tier.id);
        const tierTitle = activeTier ? activeTier.text : '';
        console.log('activeTier',this.state.b2b2cTier,filteredTiers,activeTier,numPortfoliosDropdownItems);

        const activeNumPortfoliosItem = numPortfoliosDropdownItems.find((tier) => this.state.productId == tier.id);
        const numPortfoliosTitle = activeNumPortfoliosItem ? activeNumPortfoliosItem.text : '';
        var isDesktop = this.state.systemType == System_Type.DESKTOP;

        var proceedDisabled = this.state.proceedDisabled;
        if (existingPlan) {
            if (isLegacyProduct) {
                const exDesktopAddOns = existingPlan.NumDesktopUsers - (existingPlan.SystemType === System_Type.DESKTOP ? 1 : 0);
                const exCloudAddOns = existingPlan.NumCloudUsers - (existingPlan.SystemType === System_Type.CLOUD ? 1 : 0);
                
                var numExistingAddServices = existingPlan.AdditionalServices.length;
                var numNewAddServices = 0;
                for (var key in this.state.additionalServices) {
                    if (this.state.additionalServices[key]) {
                        numNewAddServices += 1;
                    }
                }
                
                proceedDisabled = proceedDisabled || (this.state.isUpgrade && (this.state.productId <= existingPlan.ProductId && this.state.numDesktopAddOns <= exDesktopAddOns && this.state.numCloudAddOns <= exCloudAddOns && (numNewAddServices <= numExistingAddServices))) || this.state.productId === -999 || shouldProceedBeDisabledDueToCustomAUM;
            } else {
                proceedDisabled = proceedDisabled || (this.state.isUpgrade && (this.state.productId <= existingPlan.ProductId && this.state.numCloudUsers <= existingPlan.NumCloudUsers && (isDesktop ? this.state.numDesktopUsers <= existingPlan.NumDesktopUsers : true) && this.state.customAUMLimitOption <= this.state.existingCustomAUMLimitOption && (existingPlan.NumFamiliesTotal < 0 || this.state.numFamiliesTotal <= existingPlan.NumFamiliesTotal))) || this.state.productId === -999 || shouldProceedBeDisabledDueToCustomAUM;
            }
        } else {
            proceedDisabled = this.state.productId === -999 || this.state.productId == null || this.state.systemType < 0 || shouldProceedBeDisabledDueToCustomAUM;
        }

        const showAdditionalServices = activeProduct ? activeProduct.showAdditionalServices : false;
        const hideFreeLogins = activeProduct ? activeProduct.hideFreeLogins == true : false;

        var productType = -1;
        if (priceDetails && priceDetails.NewPlan) {
            productType = priceDetails.NewPlan.ProductType;
        } else {
            if (this.state.productId > (isDesktop ? 102 : 302)) {
                productType = Product_Type.ADVISOR;
            } else if (this.state.productId == (isDesktop ? 102 : 302)) {
                productType = Product_Type.PRO;
            } else {
                productType = Product_Type.INVESTOR
            }
        }

        var a = [...[1]];

        var isAdvisor = productType == Product_Type.ADVISOR;
        var isPro = productType == Product_Type.PRO;
        var premiumPackTooltipText = isAdvisor ? 
        [
            "Opt for this pack if you wish to enable the following:",
            "",
            "- Logo Branding in your MProfit client logins on web & mobile, and in all client reports.",
            "- Premium reporting in your clients' MProfit logins: Directly enable XIRR, Capital Gains ITR, Historical Valuations & all other portfolio reports in your clients' logins.",
            ...(isDesktop ? [] : ["- Reconciliation Module: Verify the accuracy of your imported data by comparing it with your holdings in CAMS AUM / Karvy AUM / broker holding statements."])
        ] :
        (
            isPro ?
            [
                "Opt for this pack if you wish to enable the following:",
                "",
                ...(isDesktop ? [] : ["- Reconciliation Module: Verify the accuracy of your imported data by comparing it with your holdings in CAMS CAS / NSDL eCAS / other holding statements."]),
                "- Premium reporting in your clients' MProfit logins: Directly enable XIRR, Capital Gains ITR, Historical Valuations & all other portfolio reports in your clients' logins.",
                "",
                "If you wish to add your logo branding in MProfit, opt for an MProfit Advisor plan and then subscribe to the Advisor Premium Pack."
            ]
            :
            [
                "Get access to MProfit's Reconciliation Module.",
                "",
                "With this module, you can verify the accuracy of your imported data by comparing it with your holdings in CAMS CAS / NSDL eCAS / holding statements."
            ]
        );

        var additionalServices = [];
        if (!hideFreeLogins) {
            additionalServices.push(
                {
                    Name: "Web & mobile logins for your clients",
                    Active: true,
                    Disabled: true,
                    ServiceType: -1,
                    Price: 0,
                    TooltipText: ["You can provide free MProfit logins to your clients on web & mobile.","Your clients can use their MProfit logins to view portfolio valuations & select reports (portfolio summary and capital gains summary)."]
                }
            )
        }

        if (this.state.additionalServices[Additional_Services.XIRR_INVESTORREPORTS]) {
        additionalServices.push(
            {
                Name: `MProfit ${productType != null ? (isAdvisor ? 'Advisor' : (isPro ? 'Pro' : 'Investor')) + ' ' : ''}Premium Pack`,
                Active: this.state.additionalServices[Additional_Services.XIRR_INVESTORREPORTS],
                Disabled: false,
                ServiceType: Additional_Services.XIRR_INVESTORREPORTS,
                Price: -1,
                IsUpgradeAfterHalf: false,
                TooltipText: premiumPackTooltipText
            }
        )
        }

        var existingAdditionalServices = this.state.existingAdditionalServices;

        if (existingPlan && (isPro || isAdvisor) && existingAdditionalServices && existingAdditionalServices[Additional_Services.PMS_PACK]) {
            additionalServices.push(
                {
                    Name: `MProfit ${productType != null ? (isAdvisor ? 'Advisor' : (isPro ? 'Pro' : 'Investor')) + ' ' : ''}PMS Pack`,
                    Active: this.state.additionalServices[Additional_Services.PMS_PACK],
                    Disabled: false,
                    ServiceType: Additional_Services.PMS_PACK,
                    Price: -1,
                    IsUpgradeAfterHalf: false,
                    // TooltipText: premiumPackTooltipText
                }
            )
        }

        additionalServices.forEach(x => {
            if (x.Price == -1) {
                var servicePricing = this.getAdditionalServicesPrice(x.ServiceType);
                x.Price = servicePricing ? servicePricing.Price : 0;
                x.IsUpgradeAfterHalf = servicePricing ? servicePricing.IsUpgradeAfterHalf : false;
                x.Disabled = true;
            }

            if (x.ServiceType == Additional_Services.XIRR_INVESTORREPORTS && this.state.additionalServices[Additional_Services.PMS_PACK]) {
                x.Hidden = true;
            }
        });

        var canBuyOnline = this.getCanBuyOnline(existingPlan, isOldExpired);

        var customAUMOptionList = this.filterCustomAUMList(getCustomAUMOptionList(this.state.B2X), this.state.isFromPlanRecommendation ? this.state.customAUMLimitOption : this.state.existingCustomAUMLimitOption, this.state.isUpgrade, this.state.isRenewalEligibleLegacyProduct);
        console.log("🚀 ~ SelectProductComponent ~ render ~ customAUMOptionList:", customAUMOptionList)
        var customAUMLimitTitle = getDropdownText(this.state.customAUMLimitOption, customAUMOptionList);

        return (
            <>
                <PricingCardModal isOpen={this.state.isPricingCardModalOpen} onCloseModal={() => this.onTogglePricingCardModal(false)} isWealth={b2x !== B2X.B2B2C}/>
                <section className={styles.paymentFTU}>
                    <div className={["container", styles.pay_container].join(" ")}>
                        <div className="row">
                            <PaymentStructure progress={30}/>
                            <div className={["tab-content", styles.payContent, "text-center", "w-100"].join(" ")}>
                                <div className={["tab-pane", "container", "select_product", "active"].join(" ")} id="select_product">
                                    <h2>Select your plan</h2>
                                    { 
                                        existingPlan && !existingPlan.IsFree &&
                                        <PlanInformation existingPlan={existingPlan}/>
                                    }
                                    {
                                        canBuyOnline
                                        ?
                                        <div className={[styles.form_container, "text-left", "shadow-sm", "bg-white", "rounded", "p-md-5", "p-3"].join(" ")}>
                                            {
                                            !this.state.isExistingPlanLoading &&
                                            <>
                                            {
                                                !existingPlan &&
                                                <div className={["form-group", "fgroup", this.state.systemType >= 0 ? "" : "mb-5"].join(" ")}>
                                                    <label>How do you wish to use MProfit?
                                                    </label>
                                                    <DropDown title={idToText(this.state.B2X,B2XDropdownOptionsWithSelectForBuyPage)} data={B2XDropdownOptionsWithSelectForBuyPage} showBorder={true} callBack={(item)=>this.selectB2X(item.id)}/>
                                                </div>
                                                // <div className={["form-group", "fgroup", this.state.systemType >= 0 ? "" : "mb-5"].join(" ")}>
                                                //     <label>Platform
                                                //         <span className="qcircle" id={'platform-tooltip'} data-toggle="tooltip" data-placement="right">?</span>
                                                //         <Tooltip text={['- MProfit Cloud: A completely web & mobile based application that allows you to update & view your portfolios on any device.', '- MProfit for Desktop: A Windows Desktop based application that allows you to sync your portfolio data for view-only access on Cloud']} header={'Select the MProfit platform you would like to use'} target={'platform-tooltip'}/>
                                                //     </label>
                                                //     <DropDown title={idToText(this.state.systemType,SystemTypes)} data={SystemTypes} showBorder={true} callBack={(item)=>this.selectSystemType(item,"systemType")}/>
                                                // </div>
                                            }
                                            {
                                                (this.state.systemType >= 0 && (isLegacyProduct || b2x >= 0)) &&
                                                <>
                                                {
                                                    b2x == B2X.B2B2C ?
                                                    <>
                                                    <div className={["form-group", "fgroup"].join(" ")}>
                                                        <label>Product</label>
                                                        <DropDown title={tierTitle} price={undefined} data={filteredTiers} showBorder={true} callBack={(item)=>this.selectB2B2CTier(item,"b2b2cTier")} disabled={true}/>
                                                    </div>
                                                    {/* {
                                                        (!existingPlan || existingPlan.IsFree) &&
                                                        <div className={["form-group", "fgroup"].join(" ")}>
                                                            <div className={styles.compareProductsBtn} onClick={() => this.onTogglePricingCardModal(true)}>View features by product</div>
                                                        </div>
                                                    } */}
                                                    {
                                                        numPortfoliosDropdownItems && numPortfoliosDropdownItems.length > 0 &&
                                                        <div className={["form-group", "fgroup"].join(" ")}>
                                                            <label>Number of client portfolios managed in MProfit</label>
                                                            <span className="qcircle" id={'sel-product-client-portfolios-tooltip'} data-toggle="tooltip" data-placement="right">?</span>
                                                            <Tooltip text={[
                                                                'MProfit lets you create client-wise portfolios.',
                                                                'Generally, you can add all investments for one client into one portfolio. Therefore, you can assume that you will need to create a number of portfolios in MProfit that equals your client count.',
                                                                'However, sometimes you might want to track certain assets separately. For example, a PMS. In this case, you might want to create a separate portfolio and thus have more than 1 portfolio per client.',
                                                                'Please also note F&O data must be added into a separate F&O portfolio, which adds to your portfolio count.',
                                                                'Our advice: Start with a portfolio count equal to the number of clients you manage, and if required - you can upgrade your plan at any time to add more portfolios!'
                                                            ]} target={'sel-product-client-portfolios-tooltip'}/>
                                                            <DropDown title={numPortfoliosTitle} price={showCustomAUMDropdown ? undefined : BasePrice} data={numPortfoliosDropdownItems} showBorder={true} callBack={(item)=>this.selectProductId(item,"productId")}/>
                                                        </div>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    <div className={["form-group", "fgroup"].join(" ")}>
                                                        <label>Product</label>
                                                        <DropDown title={title} price={showCustomAUMDropdown ? undefined : BasePrice} data={filteredProducts} showBorder={true} callBack={(item)=>this.selectProductId(item,"productId")} disabled={isLegacyProduct || this.state.isRenewalEligibleLegacyProduct}/>
                                                    </div>
                                                    {
                                                        (!existingPlan || existingPlan.IsFree) ?
                                                        <div className={["form-group", "fgroup"].join(" ")}>
                                                            <div className={styles.compareProductsBtn} onClick={() => this.onTogglePricingCardModal(true)}>Explore our pricing plans and compare features</div>
                                                        </div>
                                                        :
                                                        (
                                                            isLegacyProduct ?
                                                            <div className={["form-group", "fgroup"].join(" ")}>
                                                                <div className={styles.compareProductsBtn} onClick={() => this.contactMProfitForUpgrade(true)}>Click here to contact us to upgrade your plan</div>
                                                            </div>
                                                            :
                                                            <></>
                                                        )
                                                    }
                                                    </>
                                                }
                                                {
                                                    showCustomAUMDropdown &&
                                                    <div className={["form-group", "fgroup"].join(" ")}>
                                                        <label>Investment Value Limit</label>
                                                        <DropDown title={customAUMLimitTitle} price={!showCustomAUMDropdown ? undefined : BasePrice} data={customAUMOptionList} showBorder={true} callBack={(item)=>this.selectCustomAUMLimit(item)} disabled={this.state.isRenewalEligibleLegacyProduct}/>
                                                    </div>
                                                }
                                                {
                                                    isLegacyProduct && !isCloud &&
                                                    <SelectAddOns numAddOns={this.state.numDesktopAddOns} minNumAddOns={this.state.minNumDesktopAddOns} type={'Desktop'} adjustAddOns={this.adjustAddOns} singleAddOnPrice={SingleAddOnPrice} tooltipText={['If you need MProfit on more than one PC, you can buy additional Desktop licenses.','Desktop add-ons will have all the same features and expiry date as your Base License.']}></SelectAddOns>
                                                }
                                                {
                                                    isLegacyProduct &&
                                                    (
                                                    isCloud ?
                                                    <SelectAddOns numAddOns={this.state.numCloudAddOns} minNumAddOns={this.state.minNumCloudAddOns} type={'Cloud'} showTypeText={false} adjustAddOns={this.adjustAddOns} singleAddOnPrice={cloudSingleAddOnPrice} tooltipText={["Your MProfit base license includes one Web & Mobile login for the first user. If you would like to add more users to your MProfit account, you can buy Add-ons.","Each Add-on gives you access to one additional web & mobile login."]}></SelectAddOns>
                                                    :
                                                    <SelectAddOns numAddOns={this.state.numCloudAddOns} minNumAddOns={this.state.minNumCloudAddOns} type={'Cloud'} adjustAddOns={this.adjustAddOns} singleAddOnPrice={cloudSingleAddOnPrice} showCloudLoginMsg={true} tooltipText={["Your first Cloud login is included FREE with your MProfit plan. If you'd like to create more user logins to view your portfolios, you can buy Cloud add-ons.","One Cloud add-on gives you access to one web login and one mobile login. Cloud add-ons have the same expiry date as your Base License."]}></SelectAddOns>
                                                    )
                                                }
                                                {
                                                    !isLegacyProduct && isAdditionalUsersAllowed &&
                                                    (
                                                        <>
                                                        {
                                                            !isCloud &&
                                                            <SelectCountField count={this.state.numDesktopUsers} minCount={this.state.minNumDesktopUsers} defaultCount={this.state.numDesktopUsersDefault} fieldName={'numDesktopUsers'} adjustCount={this.adjustCount} unitPrice={SingleAddOnPrice}
                                                                fieldLabel={`Number of Desktop User Logins`}
                                                                bottomGreenText={numDesktopUsersDefault > 0 ? `${numDesktopUsersDefault} login${numDesktopUsersDefault > 1 ? 's' : ''} included. Every additional login costs Rs. ${decimal(SingleAddOnPrice,0)}` : null}
                                                                tooltipText={['If you need MProfit on more than one PC, you can buy additional Desktop licenses.','Desktop add-ons will have all the same features and expiry date as your Base License.']}
                                                            ></SelectCountField>
                                                        }
                                                        <SelectCountField count={this.state.numCloudUsers} minCount={this.state.minNumCloudUsers} defaultCount={this.state.numCloudUsersDefault} fieldName={'numCloudUsers'} adjustCount={this.adjustCount} unitPrice={cloudSingleAddOnPrice}
                                                            fieldLabel={`Number of ${!isCloud ? 'Cloud ' : ''}User Logins`}
                                                            bottomGreenText={numCloudUsersDefault > 0 ? `${numCloudUsersDefault} login${numCloudUsersDefault > 1 ? 's' : ''} included. Every additional login costs Rs. ${decimal(cloudSingleAddOnPrice,0)}` : null}
                                                            tooltipText={['Each user login includes one web login and one mobile login']}
                                                        ></SelectCountField>
                                                        </>
                                                    )
                                                }
                                                {
                                                    (this.state.showExtraFamilies) && isAdditionalFamiliesAllowed && false &&
                                                    <SelectCountField count={this.state.numFamiliesTotal} minCount={this.state.minNumFamiliesTotal} defaultCount={this.state.numFamiliesDefault} fieldName={'numFamiliesTotal'} adjustCount={this.adjustAddOns} unitPrice={cloudAdditionalFamilyPrice}
                                                        fieldLabel={'Number of Families'}
                                                        bottomGreenText={numFamiliesDefault > 0 ? `${numFamiliesDefault} ${numFamiliesDefault > 1 ? 'families' : 'family'} included. Every additional family costs Rs. ${decimal(cloudAdditionalFamilyPrice,0)}` : null}
                                                        tooltipText={[
                                                            `MProfit's Family Office plan allows you to create multiple families, so that you can manage data for multiple families / entities in a single MProfit account.`,
                                                            `You get 3 families included by default in the Family Office plan. If you need to add more families in your MProfit account, you can do so at extra cost.`
                                                        ]}
                                                    ></SelectCountField>
                                                }
                                                {
                                                    showAdditionalServices && additionalServices && additionalServices.length > 0 &&
                                                    <SelectAdditionalService services={additionalServices} toggleService={this.toggleAdditionalService} isUpgrade={this.state.isUpgrade}/> 
                                                }
                                                {
                                                    !this.state.isUpgrade && !(this.state.productId === -999) && !shouldProceedBeDisabledDueToCustomAUM &&
                                                    <>
                                                        <PriceTotal Amount={Amount} DiscountedAmount={DiscountedAmount} TotalTax={TotalTax} Discount={Discount} FinalAmount={FinalAmount} LicenseExpDt={LicenseExpDt} applyDiscountCode={this.applyDiscountCode} cancelDiscountCode={this.cancelDiscountCode} handleInputChange={this.handleInputChange} DiscountCode={this.state.discountCode} TempDiscountCode={this.state.tempDiscountCode} DiscountFields={DiscountFields} showCouponCode={this.state.existingPlan == null || this.state.existingPlan.IsFree} codeFromUrl={this.state.codeFromUrl} isEditingCouponCode={this.state.isEditingCouponCode}
                                                            editAutoAppliedDiscountCode={() => {this.setState({isEditingCouponCode: true})}}
                                                        />
                                                        {/* <div className={["form-check", "p-0"].join(" ")}>
                                                            <input type="checkbox" className="form-check-input" id="Check1" />
                                                            <label className={["form-check-label", styles.lbl_chk].join(" ")} htmlFor="Check1">I accept all terms in MProfit's  <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a></label>
                                                        </div> */}
                                                    </>
                                                }
                                                </>
                                            }
                                            </>
                                            }
                                            <ProceedButton disabled={proceedDisabled} onClick={this.proceed} loading={this.state.proceedLoading} buttonText={this.state.isUpgrade ? 'View Upgrade Options' : 'Proceed'} tooltip={this.state.isUpgrade && proceedDisabled ? {id:'upgradeButton', text: [`To upgrade, you must select a higher MProfit plan or buy more ${isCloud ? (!isLegacyProduct ? 'Users' : 'Add-ons') : 'Desktop or Cloud add-ons'} than you currently use.`,"If you're looking to simply renew your subscription as-is, you can do so within 30 days of your license expiry date."], header: 'Upgrade your MProfit subscription'} : null}/>
                                        </div>
                                        :
                                        <div className={[styles.form_container, "text-left", "shadow-sm", "bg-white", "rounded", "p-md-5", "p-3"].join(" ")}>
                                            <div className={["form-group", "fgroup", this.state.systemType >= 0 ? "" : "mb-5"].join(" ")}>
                                                <div>
                                                    Please contact MProfit Support at <a href="mailto:support@mprofit.in">support@mprofit.in</a> to subscribe or make changes to your MProfit plan. Our support team will be happy to assist you promptly.
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <DefaultLoader isLoad={this.state.isExistingPlanLoading} isFull={true}/>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedProduct: state.order.SelectedProduct
    }
  }
  
const mapDispatchToProps = dispatch => {
    return {
        setSelectedProduct: (selectedProduct) => dispatch({type: 'SET_SELECTED_PRODUCT', data: selectedProduct}),
        setPriceDetails: (priceDetails) => dispatch({ type: `SET_ORDER_PRICE_DETAILS`, data: priceDetails }),
        setOrderPlacementDetails: (orderPlacementDetails) => dispatch({ type: 'SET_ORDER_PLACEMENT_DETAILS', data: orderPlacementDetails}),
        setUpgradePriceInfo: (upgradePriceInfo) => dispatch({ type: 'SET_UPGRADE_PRICE_INFO', data: upgradePriceInfo })
    }
}

const SelectProduct = connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectProductComponent)  

export default SelectProduct